import React, { Component } from 'react'
import './OverviewPage.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from 'react-md-spinner'

export default class OverviewPage extends Component {
    constructor() {
        super()
        this.state = {
            stats: {},
            statsFetchOutstanding: false,
        }
    }

    componentDidMount() {
        this.fetchStats()
    }

    /*
    * @return {void}
    *
    * fetchStats fetches the overview stats and updates the stats object with the result
    */
    fetchStats = () => {
        this.setState({statsFetchOutstanding: true})
		fetch(`${this.props.route}/adminstats.webapi`, {
			method: 'POST',
			headers: {
				'Content-Type': 'text/plain',
				'Token': this.props.userData.Token
			}
		})
		.then((response) => {
            if (response.status === 401) {
                this.props.setAuthenticationError()
                throw new Error('Failed to authenticate request')
            } else if (response.ok) {
                return response.text()
            } else {
                throw new Error('Failed to get stats')
            }
        })
		.then((result) => {
			this.setState({stats: JSON.parse(result), statsFetchOutstanding: false})
        })
        .catch(() => {
            toast('Failed to get stats')
            this.setState({statsFetchOutstanding: false})
        })
    }

    /*
    * @return {void}
    *
    * fetchCleanDatabase deletes all data older than 2 months old. The secret phrase does need to be
    * valid, but please use this with caution
    */
    fetchCleanDatabase = () => {
        let secret = window.prompt("Enter secret phrase to clean database")
        if (secret) {
            fetch(`${this.props.route}/cleanupdatabase.webapi`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'text/plain',
                },
                body: JSON.stringify({
                    secret,
                    cleanUpTokenTable: true,
                    deleteExpiredCustomerData: true
                })
            })
            .then((response) => {
                if (response.status === 401) {
                    this.props.setAuthenticationError()
                    throw new Error('Failed to authenticate request')
                } else if (response.ok) {
                    return response.text()
                } else {
                    throw new Error('Failed to clean database')
                }
            })
            .then((result) => {
                toast(result)
            })
            .catch((error) => {
                toast(error)
            })
        }
    }

    render() {
        return (
            <div className='admin-panel-overview-container'>
                <div className='admin-panel-orders-header-wrapper'>
                    <h1 className='admin-panel-page-title-text'>Overview</h1>
                    <div className='admin-panel-overview-header-button-wrapper'>
                        <button className='admin-panel-orders-add-customer-button' onClick={this.fetchCleanDatabase}>Clean Database</button>
                        {this.state.statsFetchOutstanding ?
                            <div className='admin-panel-customer-details-spinner-container' style={{transform: 'none', left: 0}}>
                                <Spinner size={35} singleColor={'#1c4670'}/>
                            </div>
                        :
                            <button className='admin-panel-orders-add-customer-button' onClick={this.fetchStats}>Refresh</button>
                        }
                    </div>
                </div>
                <div className='admin-panel-overview-with-change-container'>
                    <p className='admin-panel-overview-with-change-title'>Today's Sales</p>
                    <p className='admin-panel-overview-with-change-value'>${this.state.stats.TotalSales}</p>
                </div>
                <div className='admin-panel-overview-with-change-container'>
                    <p className='admin-panel-overview-with-change-title'>Today's Orders</p>
                    <p className='admin-panel-overview-with-change-value'>{this.state.stats.TotalOrders}</p>
                </div>
                <div className='admin-panel-overview-with-change-container'>
                    <p className='admin-panel-overview-with-change-title'>Today's Demos</p>
                    <p className='admin-panel-overview-with-change-value'>{this.state.stats.TotalDemos}</p>
                </div>
                <div className='admin-panel-overview-with-change-container'>
                    <p className='admin-panel-overview-with-change-title'>Today's Conversions</p>
                    <p className='admin-panel-overview-with-change-value'>{this.state.stats.TotalConversions}</p>
                </div>
                <div className='admin-panel-overview-item-container'>
                    <p className='admin-panel-overview-item-title'>Total Orders</p>
                    <p className='admin-panel-overview-item-value'>{this.state.stats.TotalOrders}</p>
                </div>
                <div className='admin-panel-overview-item-container' data-test="one-week-sales">
                    <p className='admin-panel-overview-item-title'>1 Week Sales</p>
                    <p className='admin-panel-overview-item-value'>{this.state.stats.Total1Week}</p>
                </div>
                <div className='admin-panel-overview-item-container'>
                    <p className='admin-panel-overview-item-title'>2 Week Sales</p>
                    <p className='admin-panel-overview-item-value'>{this.state.stats.Total2Week}</p>
                </div>
                <div className='admin-panel-overview-item-container'>
                    <p className='admin-panel-overview-item-title'>1 Month Sales</p>
                    <p className='admin-panel-overview-item-value'>{this.state.stats.Total1Month}</p>
                </div>
                <div className='admin-panel-overview-item-container'>
                    <p className='admin-panel-overview-item-title'>2 Month Sales</p>
                    <p className='admin-panel-overview-item-value'>{this.state.stats.Total2Month}</p>
                </div>
                <div className='admin-panel-overview-item-container'>
                    <p className='admin-panel-overview-item-title'>3 Month Sales</p>
                    <p className='admin-panel-overview-item-value'>{this.state.stats.Total3Month}</p>
                </div>
                <div className='admin-panel-overview-item-container'>
                    <p className='admin-panel-overview-item-title'>6 Month Sales</p>
                    <p className='admin-panel-overview-item-value'>{this.state.stats.Total6Month}</p>
                </div>
                <div className='admin-panel-overview-item-container'>
                    <p className='admin-panel-overview-item-title'>Total Sales</p>
                    <p className='admin-panel-overview-item-value'>{this.state.stats.TotalSales}</p>
                </div>
                <div className='admin-panel-overview-item-container'>
                    <p className='admin-panel-overview-item-title'>Current Users</p>
                    <p className='admin-panel-overview-item-value'>{this.state.stats.CurrentUsers}</p>
                </div>
                <div className='admin-panel-overview-item-container'>
                    <p className='admin-panel-overview-item-title'>Users in Last Hour</p>
                    <p className='admin-panel-overview-item-value'>{this.state.stats.UsersLastHour}</p>
                </div>
                <div className='admin-panel-overview-item-container'>
                    <p className='admin-panel-overview-item-title'>Users in Last 3 Hours</p>
                    <p className='admin-panel-overview-item-value'>{this.state.stats.UsersLast3Hours}</p>
                </div>
                <div className='admin-panel-overview-item-container'>
                    <p className='admin-panel-overview-item-title'>Users in Last 6 Hours</p>
                    <p className='admin-panel-overview-item-value'>{this.state.stats.UsersLast6Hours}</p>
                </div>
                <div className='admin-panel-overview-item-container'>
                    <p className='admin-panel-overview-item-title'>Users in Last 12 Hours</p>
                    <p className='admin-panel-overview-item-value'>{this.state.stats.UsersLast12Hours}</p>
                </div>
                <div className='admin-panel-overview-item-container'>
                    <p className='admin-panel-overview-item-title'>Users in Last 24 Hours</p>
                    <p className='admin-panel-overview-item-value'>{this.state.stats.UsersLast24Hours}</p>
                </div>
                <div className='admin-panel-overview-item-container'>
                    <p className='admin-panel-overview-item-title'>Total Transactions</p>
                    <p className='admin-panel-overview-item-value'>{this.state.stats.TotalTransactions}</p>
                </div>
                <ToastContainer />
            </div>
        )
    }
}
